@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../color";

body#head-to-head {

  [data-controller="head-to-head"] {
    display: inline-block;
    position: relative;
    width: 100%;

    [data-head-to-head-target="input"] {
      color: inherit;
      background-color: $white;
      border-color: $white;
      outline: 0;
      box-shadow: none;
    }

    [data-head-to-head-target="results"] {
      font-size: smaller;
      margin-top: -3px;
      position: absolute;
      width: 100%;

      .list-group-item {
        color: inherit;
        background-color: $off-white;
        border-color: $off-white;

        &:hover {
          cursor: pointer;
        }

        &:hover, &.active {
          background-color: $white;
          border-color: $white;
        }
      }

      li:first-of-type {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }

  .profile-picture {
    height: 120px;
    width: 120px;
    z-index: 1;

    @include media-breakpoint-up(sm) {
      height: 180px;
      width: 180px;
    }
  }
}
