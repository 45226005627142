@charset "UTF-8";

@import "styles/_border";
@import "styles/_button";
@import "styles/_color";
@import "styles/_custom";
@import "styles/_font";
@import "styles/_input";
@import "styles/_turbo";
@import "styles/fragment/autocomplete";
@import "styles/fragment/avatar";
@import "styles/fragment/club-header";
@import "styles/fragment/club-logo";
@import "styles/fragment/cookie-consent";
@import "styles/fragment/dropdown";
@import "styles/fragment/form-checkbox";
@import "styles/fragment/form-control";
@import "styles/fragment/form-range";
@import "styles/fragment/form-select";
@import "styles/fragment/git";
@import "styles/fragment/header";
@import "styles/fragment/header/header-default";
@import "styles/fragment/header/header-transparent";
@import "styles/fragment/headline";
@import "styles/fragment/input-group";
@import "styles/fragment/jugendstil-header";
@import "styles/fragment/language-switch";
@import "styles/fragment/nav-bar";
@import "styles/fragment/notification-bar";
@import "styles/fragment/pagination";
@import "styles/fragment/player-autocomplete";
@import "styles/fragment/pricing-card";
@import "styles/fragment/rank-move";
@import "styles/fragment/table-draw";
@import "styles/fragment/table-match";
@import "styles/fragment/table-player";
@import "styles/fragment/table-ranking";
@import "styles/fragment/table-ranking-country";
@import "styles/fragment/table-ranking-forecast";
@import "styles/fragment/table-ranking-movement";
@import "styles/fragment/table-search-result";
@import "styles/fragment/table-user-registration";
@import "styles/fragment/thumbnail";
@import "styles/fragment/tournament-header";
@import "styles/fragment/user-menu";
@import "styles/fragment/video";
@import "styles/layout/fullscreen";
@import "styles/layout/landing-page";
@import "styles/layout/layout-landing-page-hero-image";
@import "styles/layout/layout-standard";
@import "styles/page/account-profile";
@import "styles/page/account-tournament";
@import "styles/page/club";
@import "styles/page/draw-edit";
@import "styles/page/error";
@import "styles/page/head-to-head";
@import "styles/page/homepage";
@import "styles/page/match";
@import "styles/page/player";
@import "styles/page/pricing";

$fa-font-path: "node_modules/@fortawesome/fontawesome-free/webfonts";
@import "node_modules/@fortawesome/fontawesome-free/scss/brands.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "node_modules/@fortawesome/fontawesome-free/scss/regular.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/solid.scss";

$cropper-image-path: '/image' !default;
@import "node_modules/cropperjs/src/css/cropper.scss";

@import "node_modules/lightgallery/css/lightgallery-bundle.min.css";

@import "node_modules/bootstrap/dist/css/bootstrap.min.css";

datalist {
  display: none;
}