@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../color";

body#match {

  .profile-picture {
    height: 120px;
    width: 120px;

    &.profile-picture-small {
      height: 70px;
      width: 70px;
    }

    @include media-breakpoint-up(sm) {
      height: 180px;
      width: 180px;

      &.profile-picture-small {
        height: 90px;
        width: 90px;
      }
    }
  }
}
