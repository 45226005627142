@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../color";

body#account-profile {

  .modal {
    display: block;

    .modal-body {
      height: 420px;
      padding: 0;
    }
  }

  .overlay {
    background-color: $dark-transparent;
    inset: 0;
    opacity: 1;
    overflow: hidden;
    position: fixed;
    z-index: 99;
  }

  .profile-header {
    height: 180px;

    @include media-breakpoint-up(sm) {
      height: 200px;
    }

    .profile-header-left {
      margin-bottom: -15px;
      width: 130px;

      @include media-breakpoint-up(sm) {
        margin-bottom: 10px;
        width: 180px;
      }

      .profile-picture {
        height: 120px;
        width: 120px;
        z-index: 1;

        @include media-breakpoint-up(sm) {
          height: 180px;
          width: 180px;
        }
      }
    }

    .profile-header-right {
      margin-bottom: 10px;
    }
  }
}
