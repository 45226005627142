@import "../../styles/color";
@import "../../styles/custom";

table.table-player {
  thead {
    tr {
      th {
        font-weight: 400;

        &.player-profile-picture {
          width: 90px;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        .player-profile-picture {
          border-radius: $border-radius-sm;
          height: 65px;
          width: 65px;

          &.default-avatar {
            border-color: $light-grey;
            border-style: solid;
            border-width: 1px;
          }
        }

        .player-player-url {
          color: inherit;
          text-decoration: none;
        }

        &:last-of-type {
          width: 1%;
          white-space: nowrap;
        }
      }
    }
  }
}