@import "../../styles/color";

table.table-match {
  tbody {
    tr {
      td {
        .match-player-url {
          color: inherit;
          text-decoration: none;
        }

        .match-winner-check {
          svg path {
            fill: $jugendstil-purple;
          }
        }

        &:last-of-type {
          width: 100px;
        }
      }
    }
  }
}