@import "../../styles/color";
@import "../../styles/custom";

table.table-ranking-forecast {
  thead {
    tr {
      th {
        font-weight: 400;

        &:first-of-type {
          padding-left: 1.5rem;
        }

        &:last-of-type {
          padding-left: 1.5rem;
        }

        &.ranking-forecast-profile-picture {
          width: 90px;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        &:first-of-type {
          padding-left: 1.5rem;
        }

        &:last-of-type {
          padding-left: 1.5rem;
        }

        .ranking-forecast-profile-picture {
          border-radius: $border-radius-sm;
          height: 65px;
          width: 65px;

          &.default-avatar {
            border-color: $light-grey;
            border-style: solid;
            border-width: 1px;
          }
        }

        .ranking-forecast-move {
          font-weight: 500;
        }

        .ranking-forecast-player-url {
          color: inherit;
          text-decoration: none;
        }
      }
    }
  }
}