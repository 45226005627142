$azure: #069AF3;
$black: #000000;
$black-transparent: #0000001a;
$boring-green: #66b75b;
$custard: #fcff65;
$dark-grey: #524C4E;
$dark-transparent: rgba(30, 27, 29, 0.6);
$deep-carmine-pink: #ef2c2c;
$indian-red: indianred;
$jugendstil-purple: #673ab7;
$light-grey: #f5f5f5;
$macaroni-and-cheese: #FEB42D;
$off-white: #F5F5F5;
$pearl-black: #051224;
$purple-grey: #272224;
$seashell: #F1F1F1;
$shamrock-green: rgba(0, 96, 57, 0.85);
$silver: #cbcbcb;
$transparent: transparent;
$white: #FFFFFF;

.bg-jugendstil {
  background-image: linear-gradient(109.6deg, $jugendstil-purple 11.2%, #360c81 91.1%);
}

.color-indian-red {
  color: $indian-red;
}

.color-jugendstil-purple {
  color: $jugendstil-purple;
}

.color-primary {
  color: $pearl-black;
}

.bg-dark-transparent {
  background-color: $black-transparent;
}