@import "../../node_modules/bootstrap/scss/bootstrap";

table.table-draw {
  tbody {
    tr {
      td {
        &:last-of-type {
          width: 1%;
          white-space: nowrap;

          a {
            max-width: 200px;

            @include media-breakpoint-up(sm) {
              min-width: 150px;
            }
          }
        }
      }
    }
  }
}